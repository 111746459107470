import dynamic from "next/dynamic";
import React from "react";
import { LinkGroupWrapper, Title, Space } from "./styles";

const Link = dynamic(() => import("../link/link"));

const ChildrenLinks = ({ childrenLinks }) => {
  return (
    <ul>
      {childrenLinks.map((link) => (
        <li key={link.id}>
          {link.text ? <Link {...link}>{link.text}</Link> : <Space></Space>}
        </li>
      ))}
    </ul>
  );
};

export const LinkGroup = ({
  groupTitle,
  childrenLinksColumnOne,
  childrenLinksColumnTwo,
}) => {
  const hasChildrenLinksColumnOne = childrenLinksColumnOne.length > 0;
  const hasChildrenLinksColumnTwo = childrenLinksColumnTwo.length > 0;

  return (
    <>
      <LinkGroupWrapper>
        {groupTitle && <Title>{groupTitle}</Title>}
        {hasChildrenLinksColumnOne && (
          <ChildrenLinks childrenLinks={childrenLinksColumnOne} />
        )}
      </LinkGroupWrapper>
      <LinkGroupWrapper mt>
        {hasChildrenLinksColumnTwo && (
          <ChildrenLinks childrenLinks={childrenLinksColumnTwo} />
        )}
      </LinkGroupWrapper>
    </>
  );
};

export default LinkGroup;
