import styled, { css } from "styled-components";

export const LinkGroupWrapper = styled.div`
  margin-bottom: var(--sectionSpacing);

  ${({ mt }) =>
    mt &&
    css`
      margin-top: 60px;
    `}

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: var(--textS);
    line-height: var(--lineHeightS);
    text-underline-offset: 3px;
  }

  @media screen and (max-width: 1128px) {
    ${({ mt }) =>
      mt &&
      css`
        margin-top: 0;
      `}
  }
`;

export const Title = styled.p`
  font-size: var(--textL);
  line-height: var(--lineHeightL);
  margin-bottom: var(--spacingM);
`;

export const Space = styled.div`
  margin-top: 24px;
`;

export default LinkGroupWrapper;
